//NPM Packages
import { React, useContext, useEffect } from "react";
import styled from "styled-components";

//Context
import MyContext from "../../context/MyContext";

//Data
import { OccupationIndustry } from "../../data/OccupationIndustry";

//Styled Components
import { Col } from "../styles/Col";
import { Dropdown } from "../styles/Dropdown.styled";
import { Form } from "../styles/Form.styled";
import { Input } from "../styles/Input.styled";
// import RadioButton from "../styles/RadioButton";
import { Row } from "../styles/Row";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const Label = styled.label`
  padding-left: ${(props) => props.theme.space[1]};
  padding-top: ${(props) => props.theme.space[1]};
  font-size: medium;
  color: ${({ theme }) => theme.colors.body};
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
`;

// const RadioButtonWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: left;
//   justify-content: left;

//   @media ${({ theme }) => theme.device.laptop} {
//     flex-direction: row;
//   }
// `;

// const ValidationMessage = styled.div`
//   padding: ${(props) => props.theme.space[1]};
//   margin: ${(props) => props.theme.space[0]};
//   font-size: small;
//   color: red;
// `;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
  color: red;
`;

export default function LeadDetails(props) {
  const {
    data,
    setData,
    formError,
    setFormError,
    setApplicantDetailsDataCaptured,
  } = useContext(MyContext);

  const getCurrentDate = () => {
    const dateObject = new Date();
    const date = dateObject.toISOString().split("T")[0];
    setData((prevValue) => {
      return {
        ...prevValue,
        signedDate: date,
      };
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      // Process your form submission
      getCurrentDate();
      setApplicantDetailsDataCaptured(true);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit} autocomplete="off">
      <FormHeading>
        {props.name} - {props.formName}
      </FormHeading>
      <FormSubHeading>
        Complete your company details below to continue your application
        process.
      </FormSubHeading>
      {formError && <ValidationSubHeading>{formError}</ValidationSubHeading>}
      <FormBody>
        <FormSubHeading>Lead Details</FormSubHeading>
        <ItemWrapper>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Company Name (Required)</Label>
              <Input
                type="text"
                name="companyName"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.companyName}
                width="100%"
              />
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Trading Name (Required)</Label>
              <Input
                type="text"
                name="tradingName"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.tradingName}
                width="100%"
              />
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Company Registration Number (Required)</Label>
              <Input
                type="text"
                name="companyRegistrationNumber"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.companyRegistrationNumber}
                width="100%"
              />
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Size Of Organisation (Required)</Label>
              <Dropdown
                name="sizeOfOrganisation"
                onChange={handleInfoChange}
                required={true}
                value={data.sizeOfOrganisation}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="0-50">0-50</option>
                <option value="51-100">51-100</option>
                <option value="100+">100+</option>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Tax Number (Required)</Label>
              <Input
                type="number"
                min="0"
                step="any"
                onWheel={(e) => e.target.blur()}
                name="taxNumber"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.taxNumber}
                width="100%"
              />
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>VAT Number</Label>
              <Input
                type="number"
                min="0"
                step="any"
                onWheel={(e) => e.target.blur()}
                name="vatNumber"
                onChange={handleInfoChange}
                placeholder=""
                required={false}
                value={data.vatNumber}
                width="100%"
              />
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Holding Company</Label>
              <Input
                type="text"
                name="holdingCompany"
                onChange={handleInfoChange}
                placeholder=""
                required={false}
                value={data.holdingCompany}
                width="100%"
              />
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Holding Company Registration Number</Label>
              <Input
                type="text"
                name="holdingCompanyRegNumber"
                onChange={handleInfoChange}
                placeholder=""
                required={false}
                value={data.holdingCompanyRegNumber}
                width="100%"
              />
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Industry (Required)</Label>
              <Dropdown
                name="industry"
                onChange={handleInfoChange}
                required={true}
                value={data.industry}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                {OccupationIndustry.map((industry, index) => {
                  return (
                    <option value={industry.name} key={index}>
                      {industry.name}
                    </option>
                  );
                })}
              </Dropdown>
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Company Type (Required)</Label>
              <Dropdown
                name="companyType"
                onChange={handleInfoChange}
                required={true}
                value={data.companyType}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="Company">Company</option>
                <option value="CC">CC</option>
                <option value="Sole Prop">Sole Prop</option>
                <option value="Partnership">Partnership</option>
                <option value="Other">Other</option>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Division (Required)</Label>
              <Dropdown
                name="division"
                onChange={handleInfoChange}
                required={true}
                value={data.division}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="CP">CP</option>
                <option value="SME">SME</option>
              </Dropdown>
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Deal Type (Required)</Label>
              <Dropdown
                name="dealType"
                onChange={handleInfoChange}
                required={true}
                value={data.dealType}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="finance">Finance</option>
                <option value="rental">Rental</option>
                <option value="daas">DaaS</option>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>JSE Registered (Required)</Label>
              <Dropdown
                name="jseRegistered"
                onChange={handleInfoChange}
                required={true}
                value={data.jseRegistered}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Dropdown>
            </Col>
          </Row>
        </ItemWrapper>
      </FormBody>
      <ButtonWrapper>
        <button
          className="btn btn-dark btn-md"
          type="submit"
          style={{ margin: "0 2px" }}
        >
          Next
        </button>
      </ButtonWrapper>
    </Form>
  );
}
