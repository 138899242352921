//NPM Packages
import { React, useEffect, useContext } from "react";
import axios from "axios";
import styled from "styled-components";

//Context
import MyContext from "../context/MyContext";

//Components
import LeadDetails from "../components/Lead/LeadDetails";
import LeadContact from "../components/Lead/LeadContact";
import LeadAddress from "../components/Lead/LeadAddress";

import Authentication from "../components/Shared/Authentication";
import Header from "../components/Shared/Header";
import { Wrapper } from "../components/styles/Wrapper.styled";

const FormWrapper = styled.div`
  padding: ${(props) => props.theme.space[1]};

  @media ${({ theme }) => theme.device.mobileL} {
    padding: ${(props) => props.theme.space[2]};
  }

  @media ${({ theme }) => theme.device.laptop} {
    padding: ${(props) => props.theme.space[3]};
  }
`;

export default function LeadForm(props) {
  const {
    dev,
    data,
    setData,
    docsData,
    setDocsData,

    //Authentication
    headers,
    setHeaders,
    idVerification,
    setIdVerificationRequired,
    idVerificationRequired,
    requestAuthentication,
    setRequestAuthentication,

    //Forms
    applicantDetailsDataCaptured,
    applicantContactDetailsDataCaptured,
  } = useContext(MyContext);

  //Authenticate
  useEffect(() => {
    if (!headers && !requestAuthentication) {
      setRequestAuthentication(true);
      axios
        .get(
          dev ? "http://localhost:5000/api/authenticate" : "api/authenticate"
        )
        .then(
          (response) => {
            if (response.status === 200) {
              setHeaders({
                Authorization: "Bearer " + response.data.token,
                "Content-Type": "application/json",
              });
              console.log("Laminin Response: " + response.status);
            } else {
              console.log("Laminin Response: " + response.status);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
          }
        );
    }
  }, [
    dev,
    headers,
    setHeaders,
    requestAuthentication,
    setRequestAuthentication,
  ]);

  //Set Lead Id
  useEffect(() => {
    if (
      new URLSearchParams(new URL(window.location.href).search).get("l") !==
        null &&
      !data.leadId
    ) {
      setIdVerificationRequired(true);
      setData((prevValue) => {
        return {
          ...prevValue,
          leadId: new URLSearchParams(new URL(window.location.href).search).get(
            "l"
          ),
        };
      });

      setDocsData((prevValue) => {
        return {
          ...prevValue,
          leadId: new URLSearchParams(new URL(window.location.href).search).get(
            "l"
          ),
        };
      });
    }
  }, [data, setData, docsData, setDocsData, setIdVerificationRequired]);

  return (
    <Wrapper>
      <Header
        name="iStore Business"
        description="iStore Business Lead Form"
        logoPath="../images/istore-logo.png"
      />
      {!idVerification && idVerificationRequired && (
        <Authentication
          phone="011 535 9000"
          email="financeapps@istorebusiness.co.za"
        />
      )}

      {(idVerification || !idVerificationRequired) && (
        <FormWrapper>
          {!applicantDetailsDataCaptured && (
            <LeadDetails name="iStore Business" formName="Lead Form" />
          )}

          {!applicantContactDetailsDataCaptured &&
            applicantDetailsDataCaptured && (
              <LeadContact name="iStore Business" formName="Lead Form" />
            )}

          {applicantContactDetailsDataCaptured &&
            applicantDetailsDataCaptured && (
              <LeadAddress name="iStore Business" formName="Lead Form" />
            )}
        </FormWrapper>
      )}
    </Wrapper>
  );
}
