//NPM Packages
import { React, useContext, useEffect } from "react";
import styled from "styled-components";

//Context
import MyContext from "../../context/MyContext";

//Styled Components
import { Form } from "../styles/Form.styled";
import RadioButton from "../styles/RadioButton";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormLabel = styled.label`
  padding: ${(props) => props.theme.space[1]};
  font-size: medium;
`;

// const Label = styled.label`
//   padding-left: ${(props) => props.theme.space[1]};
//   padding-top: ${(props) => props.theme.space[1]};
//   font-size: medium;
//   color: ${({ theme }) => theme.colors.primary};
// `;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
`;

const ItemInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
  }
`;

const ValidationMessage = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
`;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
  color: red;
`;

export default function RentalPeriod(props) {
  const {
    data,
    setData,
    formError,
    setFormError,
    periodRequiredError,
    setPeriodRequiredError,
    setCompanyFinancialsDataCaptured,
    setRentalPeriodDataCaptured,
  } = useContext(MyContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const back = () => setCompanyFinancialsDataCaptured(false);

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    // Account number validation
    if (data.periodRequired === "") {
      setPeriodRequiredError("Please select a Rental Period");
      isValid = false;
    } else {
      setPeriodRequiredError("");
      setFormError("");
    }

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      // Process your form submission
      setRentalPeriodDataCaptured(true);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit} autocomplete="off">
      <FormHeading>
        {props.name} - {props.formName}
      </FormHeading>
      <FormSubHeading>
        Complete the Rental Period Details below to continue your application
        process
      </FormSubHeading>
      {formError && <ValidationSubHeading>{formError}</ValidationSubHeading>}
      <FormBody>
        <FormSubHeading>Rental Period</FormSubHeading>
        <ItemWrapper>
          <FormLabel>Period Required (Required)</FormLabel>
          <ItemInnerWrapper>
            <RadioButton
              name="periodRequired"
              id="12 Months"
              onChange={handleInfoChange}
              value="12 Months"
              checked={data.periodRequired === "12 Months"}
              label="12 Months"
              required={true}
            />
            <RadioButton
              name="periodRequired"
              id="24 Months"
              onChange={handleInfoChange}
              value="24 Months"
              checked={data.periodRequired === "24 Months"}
              label="24 Months"
              required={true}
            />
            <RadioButton
              name="periodRequired"
              id="36 Months"
              onChange={handleInfoChange}
              value="36 Months"
              checked={data.periodRequired === "36 Months"}
              label="36 Months"
              required={true}
            />
            <RadioButton
              name="periodRequired"
              id="48 Months"
              onChange={handleInfoChange}
              value="48 Months"
              checked={data.periodRequired === "48 Months"}
              label="48 Months"
              required={true}
            />
            <RadioButton
              name="periodRequired"
              id="60 Months"
              onChange={handleInfoChange}
              value="60 Months"
              checked={data.periodRequired === "60 Months"}
              label="60 Months"
              required={true}
            />
          </ItemInnerWrapper>
          {periodRequiredError && (
            <ValidationMessage>{periodRequiredError}</ValidationMessage>
          )}
        </ItemWrapper>
      </FormBody>
      <ButtonWrapper>
        <button
          onClick={back}
          className="btn btn-outline-dark btn-md"
          type="button"
          style={{ margin: "0 2px" }}
        >
          Back
        </button>
        <button
          className="btn btn-dark btn-md"
          type="submit"
          style={{ margin: "0 2px" }}
        >
          Next
        </button>
      </ButtonWrapper>
    </Form>
  );
}
