//NPM Packages
import { React, useContext, useEffect } from "react";
import styled from "styled-components";

//Context
import MyContext from "../../context/MyContext";

//Styled Components
import { Col } from "../styles/Col";
import { Form } from "../styles/Form.styled";
import { Input } from "../styles/Input.styled";
import { Row } from "../styles/Row";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const Label = styled.label`
  padding-left: ${(props) => props.theme.space[1]};
  padding-top: ${(props) => props.theme.space[1]};
  font-size: medium;
  color: ${({ theme }) => theme.colors.primary};
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
`;

const ValidationMessage = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
`;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
  color: red;
`;

export default function LeadContact(props) {
  const {
    data,
    setData,
    altNumberError,
    setAltNumberError,
    emailError,
    setEmailError,
    formError,
    setFormError,
    faxNumberError,
    setFaxNumberError,
    mobileError,
    setMobileError,
    workNumberError,
    setWorkNumberError,
    setApplicantDetailsDataCaptured,
    setApplicantContactDetailsDataCaptured,
  } = useContext(MyContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const back = () => setApplicantDetailsDataCaptured(false);

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    // Tel number validation
    if (data.telNumber.length !== 10 || isNaN(data.telNumber)) {
      setWorkNumberError(
        "Please enter a valid 10-digit phone number without any spaces, example 0812345678"
      );
      isValid = false;
    } else {
      setWorkNumberError("");
      setFormError("");
    }

    // Fax number validation
    if (data.faxNumber !== "") {
      if (data.faxNumber.length !== 10 || isNaN(data.faxNumber)) {
        setFaxNumberError(
          "Please enter a valid 10-digit phone number without any spaces, example 0812345678"
        );
        isValid = false;
      } else {
        setFaxNumberError("");
        setFormError("");
      }
    }

    // Alt number validation
    if (data.altNumber.length !== 10 || isNaN(data.altNumber)) {
      setAltNumberError(
        "Please enter a valid 10-digit phone number without any spaces, example 0812345678"
      );
      isValid = false;
    } else {
      setAltNumberError("");
      setFormError("");
    }

    // Mobile number validation
    if (data.mobile.length !== 10 || isNaN(data.mobile)) {
      setMobileError(
        "Please enter a valid 10-digit phone number without any spaces, example 0812345678"
      );
      isValid = false;
    } else {
      setMobileError("");
      setFormError("");
    }

    //Email validation
    if (!data.email) {
      setEmailError("Please enter an email address.");
      isValid = false;
    } else {
      setEmailError("");
      setFormError("");
    }

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      // Process your form submission
      setApplicantContactDetailsDataCaptured(true);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit} autocomplete="off">
      <FormHeading>
        {props.name} - {props.formName}
      </FormHeading>
      <FormSubHeading>
        Complete your company contact details below to continue your application
        process.
      </FormSubHeading>
      {formError && <ValidationSubHeading>{formError}</ValidationSubHeading>}
      <FormBody>
        <FormSubHeading>Lead Contact Details</FormSubHeading>
        <ItemWrapper>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Contact First Name (Required)</Label>
              <Input
                type="text"
                name="contactFirstName"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.contactFirstName}
                width="100%"
              />
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Contact Last Name (Required)</Label>
              <Input
                type="text"
                name="contactLastName"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.contactLastName}
                width="100%"
              />
            </Col>
          </Row>
        </ItemWrapper>
        <ItemWrapper>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Business Telephone Number (Required)</Label>
              <Input
                type="number"
                min="0"
                step="any"
                onWheel={(e) => e.target.blur()}
                name="telNumber"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.telNumber}
                width="100%"
                maxLength={10}
              />
              {workNumberError && (
                <ValidationMessage>{workNumberError}</ValidationMessage>
              )}
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Business Fax Number</Label>
              <Input
                type="number"
                min="0"
                step="any"
                onWheel={(e) => e.target.blur()}
                name="faxNumber"
                onChange={handleInfoChange}
                placeholder=""
                required={false}
                value={data.faxNumber}
                width="100%"
                maxLength={10}
              />
              {faxNumberError && (
                <ValidationMessage>{faxNumberError}</ValidationMessage>
              )}
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Alternative Number (Required)</Label>
              <Input
                type="number"
                min="0"
                step="any"
                onWheel={(e) => e.target.blur()}
                name="altNumber"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.altNumber}
                width="100%"
                maxLength={10}
              />
              {altNumberError && (
                <ValidationMessage>{altNumberError}</ValidationMessage>
              )}
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Cell Number (Required)</Label>
              <Input
                type="number"
                min="0"
                step="any"
                onWheel={(e) => e.target.blur()}
                name="mobile"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.mobile}
                width="100%"
                maxLength={10}
              />
              {mobileError && (
                <ValidationMessage>{mobileError}</ValidationMessage>
              )}
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Email (Required)</Label>
              <Input
                type="email"
                name="email"
                onChange={handleInfoChange}
                placeholder="Email"
                required={true}
                value={data.email}
                width="100%"
              />
              {emailError && (
                <ValidationMessage>{emailError}</ValidationMessage>
              )}
            </Col>
          </Row>
        </ItemWrapper>
      </FormBody>
      <ButtonWrapper>
        <button
          onClick={back}
          className="btn btn-outline-dark btn-md"
          type="button"
          style={{ margin: "0 2px" }}
        >
          Back
        </button>
        <button
          className="btn btn-dark btn-md"
          type="submit"
          style={{ margin: "0 2px" }}
        >
          Next
        </button>
      </ButtonWrapper>
    </Form>
  );
}
