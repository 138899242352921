import { React, useContext } from "react";
import axios from "axios";
import styled from "styled-components";

//Context
import MyContext from "../../context/MyContext";

//Styled Components
import { Form } from "../styles/Form.styled";
import { Input } from "../styles/Input.styled";

const ButtonWrapper = styled.div`
  display: flex;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  align-items: center;
  justify-content: center;
`;

const FormHeading = styled.div`
  text-align: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
  font-family: ${(props) => props.theme.font.legal};
`;

const FormSubHeadingCentre = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
  text-align: center;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function Authentication(props) {
  const {
    dev,
    data,
    setData,
    dataFetched,
    setDataFetched,
    directors,
    headers,
    idVerification,
    setIdVerification,
    idVerificationFailed,
    setIdVerificationFailed,
  } = useContext(MyContext);

  const GetLead = (otp) => {
    const leadData = {
      leadId: data.leadId,
      companyRegistrationNumber: otp,
    };
    if (headers && !dataFetched && data.leadId) {
      setDataFetched(true);
      axios
        .post(
          dev ? "http://localhost:5000/api/get-lead" : "api/get-lead",
          leadData,
          {
            headers: headers,
          }
        )
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response: " + response.status);
              setIdVerification(true);
              SaveLead(response);
            } else {
              setIdVerificationFailed(true);
              console.log("Laminin Response: " + response.status);
            }
          },
          (error) => {
            setIdVerificationFailed(true);
            console.log("Laminin Response Error: " + error);
          }
        );
    }
  };

  const SaveLead = (response) => {
    setData((prevValue) => {
      return {
        ...prevValue,
        companyName: response.data.Fields.CompanyName,
        companyRegistrationNumber: response.data.Fields.CompanyRegNumber,
        tradingName: response.data.Fields.TradingName,
        sizeOfOrganisation: response.data.Fields.SizeOfOrganisation,
        taxNumber: response.data.Fields.CompanyTaxNumber,
        holdingCompany: response.data.Fields.HoldingCompanyName,
        holdingCompanyRegNumber: response.data.Fields.HoldingCompanyRegNo,
        industry: response.data.Fields.Industry,
        vatNumber: response.data.Fields.CompanyVatNumber,
        companyType: response.data.Fields.CompanyType,
        division: response.data.Fields.Division,
        dealType: response.data.Fields.DealType,
        jseRegistered: response.data.Fields.JseRegistered,
        contactFirstName: response.data.Fields.FirstName,
        contactLastName: response.data.Fields.LastName,
        telNumber: response.data.Fields.WorkPhone,
        faxNumber: response.data.Fields.FaxNumber,
        altNumber: response.data.Fields.AlternatePhone,
        email: response.data.Fields.Email,
        mobile: response.data.Fields.MobilePhone,
        complex: response.data.Fields.DeliveryAddressComplexUnit,
        streetAddress: response.data.Fields.DeliveryAddressStreet,
        suburb: response.data.Fields.DeliveryAddressSuburb,
        city: response.data.Fields.DeliveryAddressCity,
        province: response.data.Fields.DeliveryAddressProvince,
        postalCode: response.data.Fields.DeliveryAddressCode,
        postalComplex: response.data.Fields.PostalAddressComplexUnit,
        postalAddress: response.data.Fields.PostalAddressStreet,
        postalSuburb: response.data.Fields.PostalAddressSuburb,
        postalCity: response.data.Fields.PostalAddressCity,
        postalProvince: response.data.Fields.PostalAddressProvince,
        postalPostalCode: response.data.Fields.PostalAddressCode,
        numberOfYearsAtResidence: response.data.Fields.NumberOfYearsAtResidence,
        premisesOwnership: response.data.Fields.PremesisOwnership,
        consultant: response.data.Fields.Consultant,
        firstName1: response.data.Fields.DirMem01FirstName,
        lastName1: response.data.Fields.DirMem01LastName,
        idNumber1: response.data.Fields.DirMem01IdNumber,
        shareholding1: response.data.Fields.DirMem01Shareholding,
        willSignSurety1: response.data.Fields.DirMem01WillSignSurety,
        firstName2: response.data.Fields.DirMem02FirstName,
        lastName2: response.data.Fields.DirMem02LastName,
        idNumber2: response.data.Fields.DirMem02IdNumber,
        shareholding2: response.data.Fields.DirMem02Shareholding,
        willSignSurety2: response.data.Fields.DirMem02WillSignSurety,
        firstName3: response.data.Fields.DirMem03FirstName,
        lastName3: response.data.Fields.DirMem03LastName,
        idNumber3: response.data.Fields.DirMem03IdNumber,
        shareholding3: response.data.Fields.DirMem03Shareholding,
        willSignSurety3: response.data.Fields.DirMem03WillSignSurety,
        accountNumber: response.data.Fields.BankAccountNumber,
        bankName: response.data.Fields.BankName,
        branchCode: response.data.Fields.BankBranchCode,
        accountType: response.data.Fields.BankAccountType,
        dateOpened: response.data.Fields.BankAccountDateOpened,
        overdraftLimit: response.data.Fields.OverdraftLimit,
        netAssetValue: response.data.Fields.NetAssetValue,
        annualTurnover: response.data.Fields.AnnualTurnover,
        nameOfAuditor: response.data.Fields.NameOfAuditor,
        auditorContactPerson: response.data.Fields.AuditorContactFullName,
        auditorTelNumber: response.data.Fields.AuditorPhone,
        periodRequired: response.data.Fields.DealPeriod,
      };
    });

    if (response.data.Fields.DirMem01FirstName) {
      directors.push({
        firstName: response.data.Fields.DirMem01FirstName,
        lastName: response.data.Fields.DirMem01LastName,
        idNumber: response.data.Fields.DirMem01IdNumber,
        shareholding: response.data.Fields.DirMem01Shareholding,
        willSignSurety: response.data.Fields.DirMem01WillSignSurety,
      });
    }

    if (response.data.Fields.DirMem02FirstName) {
      directors.push({
        firstName: response.data.Fields.DirMem02FirstName,
        lastName: response.data.Fields.DirMem02LastName,
        idNumber: response.data.Fields.DirMem02IdNumber,
        shareholding: response.data.Fields.DirMem02Shareholding,
        willSignSurety: response.data.Fields.DirMem02WillSignSurety,
      });
    }

    if (response.data.Fields.DirMem03FirstName) {
      directors.push({
        firstName: response.data.Fields.DirMem03FirstName,
        lastName: response.data.Fields.DirMem03LastName,
        idNumber: response.data.Fields.DirMem03IdNumber,
        shareholding: response.data.Fields.DirMem03Shareholding,
        willSignSurety: response.data.Fields.DirMem03WillSignSurety,
      });
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    GetLead(data.otp);
  };

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  return (
    <div>
      {!idVerification && !idVerificationFailed && (
        <Form onSubmit={handleFormSubmit}>
          <FormHeading>AUTHENTICATION</FormHeading>
          <FormSubHeadingCentre>
            Please enter the Applicant Company Registration Number and click on
            Submit
          </FormSubHeadingCentre>
          <InputWrapper>
            <Input
              type="text"
              name="otp"
              onChange={handleInfoChange}
              onWheel={(e) => e.target.blur()}
              placeholder=""
              required={true}
              value={data.otp}
              width="140px"
              modwidth="140px"
            />
          </InputWrapper>
          <ButtonWrapper>
            <button
              className="btn btn-dark btn-md"
              type="submit"
              style={{ margin: "0 2px" }}
            >
              Submit
            </button>
          </ButtonWrapper>
        </Form>
      )}

      {idVerificationFailed && (
        <Form>
          <FormHeading>AUTHENTICATION FAILED</FormHeading>
          <FormSubHeadingCentre>
            Could not validate the Applicant Company Registration Number. We
            apologise for the inconvenience. Please contact us on {props.phone}.
          </FormSubHeadingCentre>
        </Form>
      )}
    </div>
  );
}
